.inline-edit {

  .inline-edit--copy--active {
    font: inherit;
    color: inherit;
    text-align: inherit;
    padding: 0;
    background: none;
    border: none;
    border-bottom: 1px dashed $wts-primary-medium;
    outline: none;
    cursor: text;
  }

  .inline-edit--copy--hidden {
    display: none;
  }

  .text-danger {
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
  }
}