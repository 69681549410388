// Some of the question answer styles are global since we're putting it outside of #configurator-app
// in the ApplyLineDefaultsModal

.step-answer-pref {
  color: gold;
  font-size: 120%;
  line-height: 1;
  display: inline-block;
}

.radio .step-answer-pref {
  margin-left: 0.25em;
}

// Move preference star below inline radio answer
.radio-inline .step-answer-pref {
  display: block;
}

.question-answer-image {
  position: relative;
  cursor: pointer;
  padding-top: calc($grid-gutter-width / 2);
  padding-bottom: calc($grid-gutter-width / 2);

  line-height: 1.2;
  text-align: center;
  border: 1px solid transparent;

  img {
    margin-bottom: 0.625em;
  }

  // Selected answer
  &.selected {
    border: 1px solid #888;
    background: #eee;

    &:after {
      @include icon-font-base();
      font: var(--fa-font-solid);
      content: "\f00c";
      font-size: 1.5em;
      position: absolute;
      top: 0.1em;
      right: 0.2em;
      color: green;
      text-shadow: 0 0 5px $white;
    }
  }

  .question-answer-image-image {
    position: relative;

    .info-link {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 1.5em;
      text-shadow: 0 0 5px $white;
    }
  }

  // Marketing text
  ul {
    margin-top: 0.5em;
    text-align: left;
    padding-left: 1em;
    font-size: 85%;
  }

  .step-answer-pref {
    padding-top: 0.25em;
  }

}

.question-answer-image-large {
  img {
    width: 150px;
  }
}

.product-select-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}

.product-select-list-item, .select-brand__tile {
  cursor: pointer;
  min-height: 150px;

  img {
    max-width: 100px;
  }
}

.product-select-tiles {
  @extend .d-grid;
  @extend .gap-3;
  grid-template-columns: repeat(auto-fit, minmax(150px, 225px));

  .product-select-tile {
    cursor: pointer;

  .question-answer-image-image {
      text-align: center;

      .info-link {
        bottom: 0.25em;
        right: 0.25em;
      }
    }
  }
}
// ConfigurePortrait and modal dialog answer images
.main-content, .modal-dialog {
  // Large
  .question-answer-image-large {
    // Make columns by breakpoint for answer tiles
    @include make-grid-sm-column(1, 3);
    @include make-grid-md-column(1, 4);
    @include make-grid-lg-column(1, 4);
    @include make-grid-xl-column(1, 5);

    /*
    // Add clearfixes since columns can be different heights
    @include breakpoint(0 $screen-xs-max) {
      &:nth-child(3n+1) { clear: left; }
    }

    @include breakpoint($screen-sm-min $screen-sm-max) {
      &:nth-child(4n+1) { clear: left; }
    }

    @include breakpoint($screen-md-min $screen-md-max) {
      &:nth-child(4n+1) { clear: left; }
    }

    @include breakpoint($screen-lg) {
      &:nth-child(5n+1) { clear: left; }
    }
    */
  }

  // Small
  .question-answer-image-small {
    // Make columns by breakpoint for answer tiles
    @include make-grid-sm-column(1, 4);
    @include make-grid-md-column(1, 6);
    @include make-grid-lg-column(1, 6);
    @include make-grid-xl-column(1, 8);

    /*// Add clearfixes since columns can be different heights
    @include breakpoint(0 $screen-xs-max) {
      &:nth-child(4n+1) { clear: left; }
    }

    @include breakpoint($screen-sm-min $screen-sm-max) {
      &:nth-child(6n+1) { clear: left; }
    }

    @include breakpoint($screen-md-min $screen-md-max) {
      &:nth-child(6n+1) { clear: left; }
    }

    @include breakpoint($screen-lg) {
      &:nth-child(8n+1) { clear: left; }
    }*/
  }
}

.question-answer-buttons {
  text-align: right;

  button:not(:last-child) {
    @extend .me-1;
  }
}

// Marketing text questions are full-width
.main-content {
  min-height: 500px;

  .question-answer-marketing-text {

    .question-answer-image {
      &.question-answer-image-large,
      &.question-answer-image-small {
        float: none;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1.5em; // Space is for question pref.
        gap: 0.5em;

        .question-answer-image-inner {
          @include make-row();

          .question-answer-image-image {
            @include make-grid-sm-column(4);
            @include make-grid-lg-column(2);
          }

          .question-answer-description {
            @include make-grid-sm-column(8);
            @include make-grid-lg-column(10);
            text-align: left;
          }
        }

        .step-answer-pref {
          align-self: center;
        }
      }
    }
  }
}

.question-answer-expand {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.question-limitations-more-less {
  padding-top: 0.5em;
}

// Less padding on small answers
.question-answer-image-small {
  padding: calc($grid-gutter-width / 4) !important;
}

.stop-type {
  @extend .text-danger;
}

.locate-unit {
  font-size: 120%;
}

.checkbox.disabled > label {
  color: #ccc;
}
// Reset list item disabled text color
.list-group-item.checkbox.disabled > label {
  color: $list-group-disabled-color;
}

.sidebar-wrapper {
  .nav-tabs {
    padding-left: 0;
  }
}

.sidebar-wrapper__controls {
  display: flex;
  padding: 1em 0em 0em 1em;
}

.summary, .summary-pricing {
  .list-group {
    background: transparent;
    border: none !important;
    border-top: none !important;

    &.list-group-root {
      padding: 0;
      overflow: hidden;

      .list-group {
        margin-bottom: 0;

        .list-group-item {
          padding-left: 30px;
        }
      }
    }

    .list-group-item {
      background: transparent;
      border: none;
      border-top: 1px solid $secondary;
      padding: 0.5em 0;
      font-weight: normal;

      &:first-child {
        border-top: none;
      }

      strong {
        font-weight: normal;
      }
    }
  }

  .summary-actions {
    padding-top: 1em;

    .btn-group-justified {
      // Add top margin to justified groups
      margin-top: 0.5em;

      // Override to display flex to fix split dropdown buttons.
      display: flex;

      // Add margins to justified buttons
      > .btn-group {
        padding-left: 0.5em;
        display: flex;
        flex: 0 1 100%;

        > .btn,
        > form > .btn {
          flex: 0 1 100%;

          &.dropdown-toggle {
            flex-basis: 0;
          }
        }

        > form {
          flex: 0 1 100%;
          display: flex;
        }
      }
      > .btn-group:first-child {
        padding-left: 0;
      }
    }

    .btn-group-condensed > .btn-group {
      padding-left: 0.25em;

      & > .btn {
        padding-left: 6px;
        padding-right: 6px;
      }

      &.start-over {
        width: 1.5%;
      }
    }
  }

  .finish-button {
    margin-bottom: 0.5em;

    .finish-indicator {
      font-size: 2em;

      &.stop-type  {
        color: $gray-200;
      }
    }
  }

  .summary__question--clickable {
    cursor: pointer;
  }
}

.tab-content--docked {
  height: 50vh;
  overflow: auto;
}

#configurator-app {

  .configurator-tabs-affix {
    background-color: $white;
    z-index: 15;
  }

  .step-complete {
    color: green;
  }

  @mixin rightArrowShape {
    font-size: 0.9375em;
    position: relative;
    color: $step-nav-color-primary;
    background-color: $step-nav-color-secondary;
    border: none;
    border-radius: 0;
    padding: 0.25em 1em 0.25em 1.8em;
    margin: 0;
    transition: none;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: calc(-1 * (1.4em + 0.5em) / 2); // Half of width
      width: 1.4em + 0.35em; // Height of tab
      height: 1.4em + 0.35em;
      // length = 1; diagonal = (1^2 + 1^2)^0.5 = 1.414 (pythagoras theorem) length = 1/1.414 = 0.707
      transform: scale(0.707) rotate(45deg);
      z-index: 1;
      background-color: $step-nav-color-secondary;
      box-shadow: 3px -3px 0 2px $white;
    }

    &:hover,
    &:hover:after,
    &:focus,
    &:focus:after {
      color: $step-nav-color-secondary;
      background-color: $step-nav-color-primary;
    }

    &:focus {
      outline: none;
    }
  }

  @mixin rightArrowShapeActive {
    color: $step-nav-active-color-primary;
    background-color: $step-nav-active-color-secondary;

    &:after {
      background-color: $step-nav-active-color-secondary;
    }

    &:hover,
    &:hover:after {
      color: $step-nav-color-secondary;
      background-color: $step-nav-color-primary;
    }
  }

  @mixin rightArrowShapePrevious {
    color: $step-nav-color-secondary;
    background-color: $step-nav-color-primary;

    &:after {
      background-color: $step-nav-color-primary;
    }

    &:hover,
    &:hover:after {
      color: $step-nav-active-color-primary;
      background-color: $step-nav-active-color-secondary;
    }
  }

  .step-nav {
    padding-top: 10px;
    border: none;
    line-height: 1.2;

    & > li {
      margin-bottom: 0.25em;

      & > button {
        @include rightArrowShape;

        // Remove BS5's setting which causes the next item to overlap this item's arrow.
        &:hover, &:focus {
          isolation: unset;
        }
      }

      &.previous {
        & > button {
          @include rightArrowShapePrevious;
        }
      }

      &.active {
        & > button {
          @include rightArrowShapeActive;
        }
      }

      // Adjust first step left padding
      &:first-child > button {
        padding-left: 0.75em;
      }
    }
  }

  .configurator-drawing-affix {
    background-color: $white;
    z-index: 10;
  }

  .configurator-selected-units-affix {
    z-index: 10;
  }

  .configurator-selected-units {
    padding-bottom: 0.5em;
    background-color: $white;

    .configurator-selected-units-inner {
      background-color: $gray-200;
      padding: 0.5em 0.5em 0 0.5em;

      .configurator-selected-units-header {
        display: flex;
        align-items: flex-start;

        h4 {
          flex-grow: 1;
          margin: 0;
          line-height: 1;
        }
      }

      .units-list {
        .btn {
          margin-right: 0.5em;
          margin-bottom: 0.5em;
        }
      }
    }
  }

  .product-photos-wrapper {
    margin-top: 1em;

    .pager {
      margin: 4px 0;
      font-size: 90%;
      line-height: 1em;

      li > a {
        padding: 4px 12px;
      }
    }
  }

  .drawing-zoom-wrapper {
    margin-top: 1em;
    position: relative;

    .pager {
      margin: 4px 0;
      font-size: 90%;
      line-height: 1em;

      li > a {
        padding: 4px 12px;
      }
    }
  }

  .drawing-wrapper {
    p.drawing-no-image {
      padding-top: 3em;
      padding-bottom: 3em;
      background-color: $gray-200;
      min-height: 150px;
      border-radius: $border-radius;
      margin-top: 1rem
    }

    .drawing {
      img {
        margin: 0 auto;
        max-height: 40vh;
      }
    }
  }

  .drawings {
    text-align: center;
    margin-bottom: 0.5em;
  }

  .configurator-mobile-info {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid $wts-primary-medium;

    .drawing-wrapper {
      padding-bottom: 0.5em;

      .drawing-no-image {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
      }
    }

    .configurator-selected-units {
      margin-top: 0.5em;
      padding-bottom: 0;
    }
  }

  .card-step-question {
    border-radius: 0;
    border: none;
    @include box-shadow(none);

    .question-unit {
      @include make-grid-sm-column(1, 4);
      cursor: pointer;

      // Add clearfixes since columns can be different heights
      &:nth-child(4n+1) { clear: left; }

      &.question-unit-selected {
        border: 1px solid #888;
        background: #eee;
      }

      .question-unit-answer-index,
      .question-unit-answer-answer {
        @include make-grid-sm-column(1, 2, 15px);
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }

    .alert {
      margin-top: $line-height-sm;
      margin-bottom: 0;
    }

    // Buttons inside float-end need to move up a little otherwise they fall out of the heading
    .float-end .btn-xs {
      margin-top: -0.25em;
    }

    // FIXME
    .Select {
      .Select-option, .Select-value {
        img {
          height: 1.5em;
          margin-top: -0.2em;
        }
      }
    }
  }

  // Don't affix summary or drawing on mobile
    @include media-breakpoint-down(sm){
      .configurator-drawing-affix,
      .configurator-summary-affix {
      position: static !important;
    }
  }


  // Utility bar
  .configurator-utility-bar {
    max-height: 50%;
    overflow-y: scroll;
    position: fixed;
    bottom: 5.5%;

    @include media-breakpoint-up(lg){
      .alert {
        min-width: 200px;
        display: inline-block;
        margin-right: calc($grid-gutter-width / 2);
        margin-bottom: 0;
        vertical-align: top;
      }
    }
  }
}

.bomTree__rootNode {
  border: none;
}

.bomTree__node {
  display: flex;

  &:active, &:hover {
    background-color: $gray-200;
  }
}

.bomTree__node--selected, .bomPart--selected {
  background-color: $gray-200;
  font-weight: 500;
}


.pageHeader--hide {
  display: none;
}

.bomHeader--affix  {
  background-color: $white;
  z-index: 10;

  > .bomHeader {
    @extend .pt-1;
  }
}

.bomSidebar--affix {
  background-color: $white;
  z-index: 10;
}

.list-group-root.list-group.bomTree__nodes.bomTree__nodes {
  max-height: 62vh;
  overflow: auto;
}

.productName-arrow {
  display: flex;
  background-color: aliceblue;
  border-radius: $border-radius;
}

.productName {
  margin: auto;
  overflow: hidden;

  h4 {
    margin-top: 10px;
  }
}

.arrow {
  font-size: 1.5em;
}

button.dockableButton {
  margin-left: auto;

  &:active:focus, &:hover:focus {
    outline: none;
  }
}

.drawing-row {
  padding-right: 0;
}

.summary-row {
  padding-left: 0;
}

.drawingActions {
  &:empty {
    display: none;
  }

  //react-select dropdown open upwards
  .Select-menu-outer {
    top: auto;
    bottom: 100%;
  }
}

.drawingActions_types {
  display: flex;
  flex: 0 0 130px;
}

.limitationsPanelTitle {
  display: inline-block;

  i {
    padding: 0 5px;
    opacity: .7;
  }
}

.limitationsPanelBody {
  ul {
    list-style-type: none;
    padding-left: 0;
  }
}

.limitationsButton {
  i {
    font-size: 1.5em;
  }
}

.summary-limitations {
  font-size: 0.875em;
}

.customMulling {
  background-color: $gray-200;
}

.summary__step-name {
  margin-right: .5em;
}

.configurator__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: auto
  }
}

.estimate__dock {
  padding: 0 .5em;
  max-height: 65vh;
  overflow: scroll;
  z-index: 10;
}

.estimate-configurator__dock {
  padding: 0 .5em;
}

.estimate__dock--affix {
  padding-top: .5em;
  max-height: 100vh;
}

.estimate__opening-not-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-200;
  height: 55vh;
  border-radius: $border-radius;
}

.estimate__images {
  display: flex;
}

.estimate__image-thumbnail {
  margin-right: 1em;

  img {
    width: 150px;
    cursor: pointer;
    border-radius: $border-radius;
    border: 1px solid $gray-200;
  }
}

.estimate__opening, .estimate__openingDetails {
  font-size: $font-size-sm
}

.estimate__openingHelp {
  margin-left: 5px;
}

.configuratorFooter {
  .nav-item + .nav-item {
    @extend .mx-1;
    @extend .mt-1, .mt-lg-0;
  }
}

.summaryCards {
  @extend .mt-2;
}

.summaryCards, .limitationCards {
  @extend .stack__card--m-1;

  .card-header {
    @extend .p-0;
  }
}