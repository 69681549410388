.brandbar-logo {
  background-image: url('https://36qyuzoj7fgnibljtmes.blob.core.windows.net/marketingimages/westernwindows/wws_logo.png');
}

// Fix for Command Bar button text.
#navbarSupportedContent {
  .wts-button.btn-sm {
    span {
      color: $wws-brand-secondary;
    }
  }
}