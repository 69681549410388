#global-changes-app {

  .suggestion-drawing {
    @extend .m-1;
    text-align: center;
    
    img {
      width: 50%;
      max-width: 200px;
    }
  }
}

.global-changes-custom-pricing-report {
  min-height: 25vh;
  max-height: 75vh;
  overflow: auto;
}
