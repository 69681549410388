.my-quotes-grid__perPage {
  display: inline-block;
  align-items: center;
  margin-left: 1rem;

  select {
    display: inline-block;
    width: auto;
    margin: 0 0.2rem;
  }
}

.grid__sortableColumn {
  cursor: pointer;
  white-space: nowrap;
  i {
    margin-left: 0.1rem;
  }
}

.grid__sortableIcon {
  position: relative;
  opacity: 0.3;
  margin-left: 0.2em;
  top: 0.12em;

  svg[data-icon] {
    position: absolute;
    top: 0.1em;
  }
  svg[data-icon]:last-child {
    bottom: 0.12em;
  }
}

.grid__selectedSortableIcon {
  position: relative;
  opacity: 1;
  margin-left: 0.2em;
  svg[data-icon] {
    position: absolute;
  }
  svg[data-icon]:last-child {
    bottom: 0.12em;
  }
}

.grid__perPage {
  display: inline-block;
  align-items: center;
  margin-left: 1em;

  select {
    display: inline-block;
    width: auto;
    margin: 0 .2em;
  }
}

.grid__dataCount {
  display: inline-block;
  margin: 20px 20px 20px 0px;
}

.my-quotes-header {
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: flex-end;
    gap: .25em;

    > div {
      flex: 1 1 60%;
    }
  }
}