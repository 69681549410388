@import './variables';
@import '~$webcp-sass/app.scss';

@import '_mixins';

@import './apps/login';
@import './apps/home';
@import './apps/configurator';
@import './apps/my-quotes';

@import './components/terms-conditions-dialog';
@import './components/jumbotron-modals';
@import './components/badge';
@import './components/footers';
@import './components/header';

@import './components/user-inputs/buttons';
@import './components/user-inputs/toggle';

.order-review-border {
  border: 1px solid $gray-300 !important;
}
