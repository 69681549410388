.interceptor-debug {
  display: inherit;

  border: 1px solid $orange-200;

  &.interceptor-debug--customized {
    > svg,
    > .interceptor-debug--ui-trigger-wrapper > svg { @extend .text-success; }
  }
}

.popover.interceptor-debug--ui {
  max-width: $popover-max-width * 2;

  .popover-body {
    padding: 0;
  }

  .interceptor-context-info {
    max-height: 20em;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
  }
}