html {
  min-height: 100vh;
}

body {
  color: $wts-text;
  min-height: 100vh;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;

  &.wait-spinner {
    .spinner {
      opacity: 1;
      display: block;
    }
  }
}

:root {
  // Add breakpoints to css vars for use in React
  @each $name, $width in $grid-breakpoints {
    --#{$variable-prefix}breakpoint-#{$name}: #{$width};
  }
}

.spin-icon-base {
  display: inline-block;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  padding: 1em;
  background-color: $light;
  font-size: 2em;
  line-height: 1;
  position: relative;
  top: 55%;
  transform: translateY(-50%);
  color: $white;
}

.spinner {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  background-color: rgba($white, 0.3);
  z-index: $z-index-spinner;
  text-align: center;

  .spin-icon {
    @extend .spin-icon-base;
  }
  &.visible {
    display: block;
  }
}

.loading-overlay {
  position: relative;

  &.loading > .spinner {
    opacity: 1;
    display: block;
    position: absolute;
  }
}

.react-query-isFetching {
  position: fixed;
  top: .5rem;
  right: .5rem;
  font-size: 1.5rem;
  transition: 0.3s ease;
  pointer-events: none;
  opacity: 0;
  z-index: $zindex-fixed;
}

.react-query-isFetching--show {
  opacity: 1;
}

.inline-spinner {
  @extend .spinner;
  display: block;
  position: relative;

  .spin-icon {
    transform: none;
  }
}

.skip-navigation {
  @extend .visually-hidden-focusable;

  display: block;
  background-color: $secondary;
  text-align: center;
}

.expand-control {
  .fa-circle-plus, .fa-circle-minus {
    font-size: 1rem;
  }
}

.pagination a {
  cursor: pointer;
}

.modal .modal-loading {
  position: relative;
  height: 7.5em;
  width: 100%;
  text-align: center;

  .spin-icon {
    @extend .spin-icon-base;
  }
}

a {
  text-decoration: none;
  outline: 0;
  transition: color 0.2s ease-in-out,
              background-color 0.2s ease-in-out,
              border-color 0.2s ease-in-out;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

hr {
  background-color: $wts-primary-medium;
}

.col-table-tiny {
  padding-left: 4px;
  padding-right: 4px;
}

.brandbar-logo {
  position: absolute;

  display: block;
  background-image: url(https://paradigmomni.blob.core.windows.net/assets/images/paradigm-logo.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  width: 24em * 0.85; // offset increase in relative font size
  height: 3.5em;
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}

.banner-text {
  font-size: $font-size-sm;
  color: $wts-text;

  ul {
    list-style: none;
  }
}

.banner-text__cb {
  font-size: $font-size-sm;
  color: $wts-text;
  @extend .lh-sm;

  ul {
    list-style: none;

    li {
      @extend .text-center
    }
  }
}

.btn {
  font-weight: normal;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.btn-primary {
  background-color: $primary;
  color: $wts-highlight-reverse;
  border-color: shade-color($primary,5%);
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    background-color: shade-color($primary,10%);
    border-color: shade-color($primary,15%);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    color: shade-color($primary, 40%);
    background-color: tint-color($primary, 20%);
    border-color: $primary;
  }
}

.breadcrumb {
  padding-left: 0;
  border: none;
  background-color: transparent;
  border-radius: 0;
  color: $secondary;

  .active {
    color: $secondary;
  }

  @include media-breakpoint-up(md){
    margin-top: -20px;
    padding-top: 20px;

    transition: all .2s;
    opacity: .6;

    &:hover {
      opacity: 1;
    }
  }

  a {
    color: $secondary;
    text-decoration: none;

    @include media-breakpoint-up(lg) {
      border-bottom: dashed 1px rgba($gray-600, 0);

      &:hover {
        border-bottom: dashed 1px rgba($gray-600, 1);
        transition: all .4s;
      }
    }
  }
}

#content {
  @extend .pt-3;
  padding-bottom: 10em;
  min-height: 70%;

  .content-tos {

    h2 {
      font-size: 1.5em;
    }

    ol li {
      margin-bottom: 1em;
    }

    ol {
      list-style-type: lower-alpha;

      > ol {
        list-style-type: lower-roman;
      }
    }

  }
}

#content-main {
  // Prevent outline when focusing from Jump to Content link.
  &:focus {
    outline: none;
  }
}

.content-inner.no-padding {
  padding-top: 0 !important;
}

.action-nav {
  border: 1px solid $wts-primary-medium;
  font-size: 0.875em;
  border-radius: 0;
  background-color: transparent;

  .navbar-form {
    border: none;
    margin: 0;
    padding: 0.5em 0.5em;
  }

  .btn,
  .btn-secondary {
    border: 1px solid $secondary;
    margin: 0 0.125em;
  }
}

.nav-tabs {
  > li {
    > a {
      color: $secondary;
      background-color: $light;
    }
  }

  > li.active > a {
    background-color: $secondary;
    color: $white;
    border-color: $secondary;
  }
}

.nav-pills {
  > .nav-item {
    &.active {
      background-color: transparent;

      .nav-link {
        background-color: $secondary;
        border-color: $secondary;
        color: $white;
      }
    }

    > .nav-link.active {
      background-color: $secondary;
      color: $white;
    }
  }
}

.customers__selected_file--info {
  display: flex;
  align-items: center;

  button {
    align-self: flex-start;
    margin-left: auto;
  }
}

.app-version {
  font-size: 75%;
  color: $light;
}

.two-step-total strong:nth-child(1) {
  color: $secondary;
  margin-left: 1.5em;
}

#update-pricing-modal {
  .modal-body {
    padding-bottom: 0px;
  }
}

.dealer-pricing-label {
  font-size: 135%;
}

.powered-by {
  width: 150px;
}

.img-preview {
  display: inline-block;
  height: 5rem;
  padding-right: 1rem;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.tiny {
  font-size: $font-size-sm;
}

.quoteheader__shipping {
  word-break: break-word;
}

.quote__shipping-address__job-site-delivery--active {
  label {
    font-weight: bold;
  }
}

.actionLinks > form {
  display: flex;
  justify-content: flex-end;
}

.actionLinks__left {
  display: flex;
  justify-content: flex-start;
}

.actionLink__item {
  max-height: 2rem;

  &:not(:last-child) {
    margin-right: .2rem;
  }
}

.actionLink__statusIndicators {
  display: flex;
  flex-direction: column;
}

.table-empty-row {
  text-align: center;
  background-color: $gray-200
}

.bold-text {
  font-weight: 500;
  font-size: 1.2em;
}

.increased-click-area {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 2rem;
  margin: -2rem;
}

.help-text-block {
  display: inline-block;
  border-left: .2rem solid rgba($primary, .5);
  padding: .5rem;
  background-color: $gray-200;
  vertical-align: middle;
}

.product-select-search-form, .favorite-search {
  display: flex;
  margin-bottom: 1em;
}

.lazy-container {
  display: block;
  position: relative;
}

.wts-info {
  position: relative;
  padding: 2rem 1rem;
  border: 2px solid $primary;
  margin: 1rem;
  border-radius: $border-radius;
}

.wts-info__header {
  position: absolute;
  left: 3%;
  top: -8%;
  background: $white;
  padding: 0rem .5rem;
  font-weight: bold;
  text-transform: uppercase;
  font-size: .8em;
  color: $wts-text;;
}

.wts-info__text {
  background: linear-gradient(to right, $white, rgba($primary, .3));
  color: $wts-text;
  margin: 0rem -1rem;
  padding: .5rem 1rem
}

.reactAppsPortalContainer {
  display: flex;
  align-items: center;
}

.rotate30 {
  transform: rotate(30deg)
}

.wtsInput__inlineHelp {
  margin-left: 1em;
  display: inline-block;
}

.addressLines {
  display: flex;
}

.addressLines__line1 {
  flex: 0 0 25%;
  margin-right: .5em;
}

.addressSuggestion {
  cursor: pointer;
  margin: .2em;
  padding: .2em;
  border-radius: $border-radius;

  &:hover {
    background-color: $gray-200;
  }
}

.addressSuggestion__poweredByLogo {
  @extend .mt-2;
}

.privacy-policy {
  border-bottom: 1px solid;
}

.header {
  border-bottom: solid 2px $gray-200;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  div > span {
    font-size: 1.5rem;
  }

  &--lineNumber, &--separator, &--quantity {
    margin-left: 1rem;
  }

  &--back {
    font-size: 1.5rem;
    opacity: .7;
  }

  .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone {
    margin: 0 0.5rem 0 0.5rem;
  }

  &--currentStep {
    text-align: center;
  }
}

.actions-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input-group {
  select, button {
    border: solid 1px $input-border-color;
  }
}

.table-responsive__overflow--inherit {
  @extend .table-responsive;
  overflow: inherit;
}

#user-alert-message {
  @extend .mb-0;
  @extend .py-2;
  @extend .px-0;
  border-width: 0 0 1px 0;
  border-radius: 0;

  p {
    @extend .mb-0;
    @extend .ms-1;
  }
}

.navbar-dark .btn-light {
  background-color: $white;
}

.customer-address-navigation {
  @extend .mb-2;
  @extend .d-flex;
  @extend .align-items-center;

  > h2 {
    @extend .ms-2;
  }
}

.drawing-icon {
  @extend .d-flex;
  @extend .pt-1;

  color: $wts-text;

  &:focus {
    @extend .shadow-none;
  }

  span {
    @extend .fs-3;
    @extend .opacity-50;

    transition: all .2s;

    &:hover {
       @extend .opacity-75;
    }
  }
}

.line-item-drawing {
  width: 100%;
  display: flex;
  margin: 0 auto;

  @include media-breakpoint-down(lg) {
    width: 50%;
  }
  @include media-breakpoint-down(md) {
    width: 80%;
  }

  > div {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 50%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top center;
    }
  }
}