.jumbotron-modal {
  .jumbotron {
    .reminder-icon {
      font-size: 2em;
      background: $gray-200;
      padding: 0.75em;
      border-radius: 50%;
      display: inline-block;
      line-height: 1em;
      margin-bottom: 0.75em;
    }
  }
}
