#configurator-app {
  #configuratorNavbarSupportedContent {
    label {
      color: $white !important;
    }
  }
  // Fix colored corners from appearing on the previous step.
  .step-nav > li:not(:first-child) {
    margin-left: 3px;
  }

  // Configurator step nav.
  .step-nav-wrapper {
    li {
      // Default steps
      & > button {
        @include configurator_step_styles(
          $white,
          $wws-brand-secondary,
          $white,
          $white
        );

        &:hover,
        &:focus {
          @include configurator_step_styles(
            $white,
            $wws-primary,
            $white,
            $white
          );
        }
      }

      // Previous steps
      &.previous > button {
        @include configurator_step_styles(
          $white,
          $wws-brand-blue,
          $white,
          $white
        );

        &:hover,
        &:focus {
          @include configurator_step_styles(
            $white,
            $wws-primary,
            $white,
            $white
          );
        }
      }

      // Active steps
      &.active > button {
        @include configurator_step_styles($white, $wws-primary, $white, $white);

        &:hover,
        &:focus {
          @include configurator_step_styles(
            $white,
            $wws-primary,
            $white,
            $white
          );
        }
      }
    }
  }

  // Remove border from the back arrow of the bom page
  .bomHeader > .btn-icon-only {
    border-color: transparent !important;
  }

  // Color Question Headers
  .card-step-question .card-header {
    background-color: $wts-primary-light;
    color: $white;

    // Caution icon on question header
    .stop-type {
      color: $white !important;
    }
  }

  // Color Footer
  .navbar-dark {
    background-color: $wws-brand-blue !important;

    #configuratorNavbarSupportedContent {
      .col-form-label {
        color: $white !important;
      }

      .btn-light {
        color: $wws-brand-secondary !important;
      }
    }
  }
}
