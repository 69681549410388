// Bootstrap 5 drops this class, so we're recreating it.
.form-group {
  @extend .mb-3;
}

// Legend as a form-label is too large
fieldset > legend.form-label {
  font-size: 1em;
}

// Remove the bottom margin on the last form-group inside a card-body to clean
// up extra spacing.
.card-body > .form-group:last-child {
  @extend .mb-0;
}