.btn-success,
.btn-primary,
.search-modal__submit {
  @include btn-style-override($white, $wts-primary-dark, $wts-primary-dark);
}

// Fix for font color on light buttons (PS-115822)
.navbar-dark .btn-light {
  color: $gray-600;
}

// Override styles for New Quote button in header.
#secondary_navbar__newQuote,
#secondary_navbar__quickConfigure {
  color: $white;
  border-color: $white;

  &:focus {
    box-shadow: 0 0 0 0.25rem transparentize($white, 0.5) !important;
  }
}
