.public {
  position: relative;
  min-height: 100vh;
  padding-bottom: 3em;
  background: $secondary url(/images/login.jpg) center center fixed;
  background-size: cover;

  header {
    background-color: rgba($white, 0.6);
    text-align: left;
    padding: 0.75em 0 0.5em 0;

    .container {
      margin-left: 0;
    }

    h1 {
      font-size: 1em;
      display: inline-block;
      width: 15em;
      height: 3.5em;
      background: url(https://paradigmomni.blob.core.windows.net/assets/images/paradigm-logo.png) no-repeat center;
      background-size: contain;
      text-indent: 200%;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 0;
    }
  }

  #content {
    max-width: 40em;
    padding: .5em;
    margin: 0 auto 0 auto;

   @include media-breakpoint-up(lg){
      margin: 6em auto 0 auto;
      padding: 3em;
    }

    background-color: rgba($white, 0.8);

    .container {
      width: 100%;
    }

    h1, h2, h3 {
      @extend .text-center;
    }

    .form-group {
      margin: 0 0 1em 0;
    }

    input[type=text],
    input[type=password] {
      border: 1px solid $wts-primary-medium;
      background-color: $white;
      color: $secondary;
      box-sizing: border-box;
      padding: 0.5em;
    }

    input[type=submit] {
      background-color: $primary;
      transition: all 0.2s ease-in-out;
      border: none;
      color: $wts-highlight-reverse;
      font-weight: $headings-font-weight;

      &:hover,
      &:focus {
        background-color: shade-color($primary, 10%);
      }
    }

    &.login__register {
      max-width: 60em;
    }
  }
}