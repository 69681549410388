textarea {
  resize: vertical;
}

.form-condensed .form-group {
  margin-bottom: 0;
}

.form-group.required {
  .col-form-label:after,
  .checkbox > label:after {
    content: " *";
    color: $danger;
  }
}

.form-actions {
  text-align: right;

  .btn:not(:last-child)  {
    @extend .me-2;
  }
}

.radio, .radio-inline {
  input[type="radio"] {
    margin-top: 2px; // Fix line height
  }
}

form.form-single-button {
  display: inline;

  .btn {
    margin-left: 5px;
  }
}

.form-control-plaintext.border-dashed {
  padding-left: $padding-base-horizontal;
  padding-right: $padding-base-horizontal;
  border: $input-border-width dashed $gray-400;
}

.input-group--tight {
  .input, .form-control-sm, .form-control {
    padding-left: 5px;
    padding-right: 1px;
  }
}

#quote-header-app {
  .checkbox, .radio {
    label {
      font-weight: 700;
    }
  }
}