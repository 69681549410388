// Toggles/Switches
.form-switch > .form-check-input {
  border-color: $wws-brand-blue;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2811, 24, 56, 1%29'/%3e%3c/svg%3e");

  &:focus,
  &:hover {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2811, 24, 56, 1%29'/%3e%3c/svg%3e");
    box-shadow: 0 0 0 0.25rem transparentize($wws-brand-blue, 0.75);
  }

  &:checked {
    border-color: $wws-brand-blue;
    background-color: $wws-brand-blue;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e");
  }
}

.configurator__keyboard {
  color: $white;
}
