// Add an additional xs button size.
.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-sm, $btn-border-radius-sm);
}

.btn-group-xs > .btn { @extend .btn-xs; }

.btn-pill {
  border-radius: $font-size-base + $btn-padding-y;

  &.btn-lg {
    border-radius: $font-size-lg + $btn-padding-y-lg;
  }
  &.btn-sm {
    border-radius: $font-size-sm + $btn-padding-y;
  }
  &.btn-xs {
    border-radius: $font-size-sm + $btn-padding-y-sm;
  }

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      outline: 0;
    }
  }
}

.btn-default {
  @extend .btn-outline-secondary
}

.btn.ellipsis-menu {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  padding: 0;
  border-radius: 50%;
  overflow: hidden;

  line-height: 1;
  font-size: $btn-font-size;
  width: ($btn-font-size * $btn-line-height) + ($btn-padding-y * 2);
  height: ($btn-font-size * $btn-line-height) + ($btn-padding-y * 2);

  &.btn-lg {
    font-size: $btn-font-size-lg;
    width: ($btn-font-size-lg * $btn-line-height) + ($btn-padding-y-lg * 2);
    height: ($btn-font-size-lg * $btn-line-height) + ($btn-padding-y-lg * 2);
  }
  &.btn-sm {
    font-size: $btn-font-size-sm;
    width: ($btn-font-size-sm * $btn-line-height) + ($btn-padding-y-sm * 2);
    height: ($btn-font-size-sm * $btn-line-height) + ($btn-padding-y-sm * 2);
  }
  &.btn-xs {
    font-size: $btn-font-size-sm;
    width: ($btn-font-size-sm * $btn-line-height) + ($btn-padding-y-xs * 2);
    height: ($btn-font-size-sm * $btn-line-height) + ($btn-padding-y-xs * 2);
  }

  // Outline variant to set base colors just like btn-default.
  @include button-outline-variant($color: map-get($theme-colors, 'secondary'));

  // Override background and border to remove outlines.
  background-color: $gray-200;
  border-color: $gray-200;

  &:hover {
    background-color: map-get($theme-colors, 'secondary');
    border-color: map-get($theme-colors, 'secondary');
  }

  &.dropdown-toggle:after {
    // Remove the toggle icon.
    display: none;
  }
}


/* btn-ring-* classes
  .btn-ring-primary
  .btn-ring-secondary
  .btn-ring-success
  .btn-ring-info
  .btn-ring-warning
  .btn-ring-danger
  .btn-ring-light
  .btn-ring-dark
*/

@each $color, $value in $theme-colors {
  .btn-ring-#{$color} {
    @include button-variant(tint-color($value, $btn-ring-lighten), $value);
  }
}

// Opt-in flex button, used by ButtonInternal.
.wts-button {
  > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;

    > svg {
      margin-top: 0.25em - 0.0625em; // Half line height minus half original FA vertical align.
      margin-bottom: 0.25em + calc(0.0625em / 2);
      vertical-align: 0;
    }
  }
}