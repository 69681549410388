.history{
  @include spinner;

  .history__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    h3 {
      margin: auto;
    }
  }

  &-wait-spinner >.spinner {
    opacity: 1;
    display: block;
  }

  &-slide-pane {
    &__header-item {
      margin-bottom: 1rem;

      >span:nth-child(1) {
        font-weight: 500;
        margin-right: .4rem;
      }
    }
  }

  &-navigation {
    border-right: 1px solid $border-color ;
    padding-right: 0px;
    min-height: 50vh;
    min-width: 10rem;


    &__item {
      display: block;
      text-decoration: none;
      padding-bottom: 1rem;
      padding-top: 1rem;
      opacity: .7;
      transition: all .2s;
      cursor: pointer;

      a {
        font-size: 1.3em;
      }

      &--nontree {
        padding-left: 2rem;
      }

      &--active {
        opacity: 1;
        border-left: .2rem solid $primary;
        background-color: rgba($light, .2);
      }

      &:hover, :focus, :active {
        text-decoration: none;
      }

      &:hover {
        border-left: .2rem solid rgba($primary, .5);
        background-color: rgba($light, .2);
      }

      &:focus {
        outline: none;
        outline-offset: 0px;
      }
    }

    &__child {
      a {
        font-size: 1.1em;
      }
    }
  }
}

.table > tbody > tr  {
  > .history-slide-pane__content-name {
    border-top: none;
    font-weight: 500;
  }

  > .history-slide-pane__content-value {
    border-top: none;
    border-bottom: 1px dotted rgba(0, 0, 0, .3);
    text-align: center;
  }

  > .history-slide-pane__content-icon {
    text-align: center;
    opacity: .7;
    border-top: none;
  }

  > .history__info {
    padding: .5em .0em;
    text-align: center;
  }
}