.terms-conditions-dialog {
  .modal-body {
    max-height: 450px;
    overflow-y: auto;

    p {
      line-height: 1.5em;
    }
  }
}

