.public {
  background-image: url('https://36qyuzoj7fgnibljtmes.blob.core.windows.net/marketingimages/westernwindows/wws_login.jpg');

  header {
    background-color: $white;

    h1 {
      background-image: url('https://36qyuzoj7fgnibljtmes.blob.core.windows.net/marketingimages/westernwindows/wws_logo.png');
    }
  }

  #content {
    background-color: $white;
  }
}
