@import "deposits";

.quote-line-items {
  h2 {
    font-size: 1.267em;
    font-weight: bold;
  }

  .line-items-filter-affix {
    padding-top: 0.5em;
    background-color: $white;
    z-index: 10;
    border-bottom: 1px solid $wts-primary-medium;
  }

  .btn-toolbar {
    margin-bottom: calc($line-height-sm / 2);
  }

  .form-group.form-control-sm {
    margin-bottom: 5px;
  }

  .part-drawing {
    padding-bottom: 1em;

    img {
      max-height: 50px;
      max-width: 50px;

      @include media-breakpoint-up(md) {
        max-height: 60px;
        max-width: 60px;
      }

      @include media-breakpoint-up(lg) {
        max-height: 75px;
        max-width: 75px;
      }
    }
  }

  .line-item-status-wrapper {
    padding-left: 2.75em !important;
  }

  .line-item {

    .wts-react-select-container.line-item-move {
      font-size: 0.875em;
    }

    .line-item--status {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 2.25em;
      border-bottom-left-radius: $card-border-radius;
      padding: 0.5em 0;

      svg {
        display: block;
        cursor: help;
        @extend .p-1;
        @extend .mx-auto;
        @extend .mb-1;
      }

      &.line-item--status--none {
        background-color: $gray-100;

        svg { color: $wts-text; }
      }
      &.line-item--status--info {
        background-color: $info;

        svg { color: $wts-text; }
      }
      &.line-item--status--success {
        background-color: $success;

        svg { color: $white; }
      }
      &.line-item--status--warning {
        background-color: $warning;

        svg { color: $wts-text; }
      }
      &.line-item--status--danger {
        background-color: $danger;

        svg { color: $white; }
      }
    }

    .lineItem_description,
    .childLineItem_description {
      white-space: pre-wrap;
    }

  }

  .child-line-item {
    @extend .border-bottom;
    &:last-child {
      @extend .border-0;
    }

    .child-line-item-status {
      border-bottom-left-radius: 0;
    }
  }

  .parts-grid {
    input.part-qty {
      min-width: 45px;
    }
  }

  .sku-grid {
    input.sku-qty {
      min-width: 45px;
    }
  }

  .line-item-specialOrder-icon {
    > img, >svg {
      height: 0.8em;
      vertical-align: baseline;
      margin-right: 0.25em;
    }
  }

  .line-item-note-icon {
    padding-right: 5px;
  }

  .line-item-note-count {
    margin-top: -10px;
    margin-left: 2px;
    background-color: $gray-200;
  }
}

.price {
  border: 1px dashed grey;
  border-radius: $border-radius-lg;
  padding: 8px;
  cursor: pointer;
  margin: 5px;
  transition: all .2s;

  &:hover {
    background-color: rgba($secondary, .2);
  }
}

.wcp-build-more__row__delete {
  margin-top: 20px;
}

.lineitems-price-breakdown {
  .form-group {
    margin-left: 0;
  }

  select {
    display: inline-block;
    width: 33%;
    margin-left: .5rem;
  }
}

.parts-tracker {
  min-height: 200px;
  position: relative;
}

.parts-tracker__empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: .8;
}

.line-item-checkbox {
  border-radius: 20px;

  &:hover {
    overflow: hidden;
    background-color: $gray-200;
  };
}

.line-item-row {
  display: flex;
  align-items: center;
}

.lineItem_actionButtons {
  @extend .text-end;
}

.pricing-details-link {
  margin-left: 15px;
  position: absolute;
  z-index: 1;
  margin-top: 7px;
}

#consistency-check-container {
  .consistencyCheck__results {

    thead th,
    tbody > tr:nth-of-type(even) > th,
    tbody > tr:nth-of-type(even) > td {
      background-color: $white;
    }

    tbody > tr:nth-of-type(odd) > th,
    tbody > tr:nth-of-type(odd) > td {
      background-color: #fdfdfd;
    }

    thead th:first-child,
    tbody th {
      position: sticky;
      left: 0;
      z-index: 1;
    }
  }
}
