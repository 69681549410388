// Hides the label on a homepage tile.
@mixin hide-tile-label {
  h2 {
    display: none;
  }
}

// Overrides button styles.
@mixin btn-style-override($color, $background-color, $border-color) {
  background-color: $background-color;
  border-color: $border-color;
  color: $color;

  &:disabled {
    background-color: $background-color;
    border-color: $border-color;
    color: $color;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: darken($background-color, 5%);
    border-color: $border-color;
    color: $color;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem transparentize($background-color, 0.75) !important;
  }
}

// Configurator step styles.
@mixin configurator_step_styles(
  $color,
  $background,
  $success-icon-color,
  $error-icon-color
) {
  color: $color;
  background-color: $background;

  &:after {
    background-color: $background;
  }

  // Check icon style.
  svg.fa-check {
    color: $success-icon-color !important;
  }

  // Error icon style.
  svg.fa-triangle-exclamation {
    color: $error-icon-color !important;
  }
}

// Dark footer styles.
@mixin dark-footer-style-override($background-color) {
  background-color: $background-color !important;
}
