.homepage-grid-configurable {
  .homepage-grid__tile {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 0;

    &:nth-child(1) {
      background-image: url('https://36qyuzoj7fgnibljtmes.blob.core.windows.net/marketingimages/westernwindows/landingpage/Box-1-Generation-369x400.jpg');
      @include hide-tile-label();
    }

    &:nth-child(2) {
      background-image: url('https://36qyuzoj7fgnibljtmes.blob.core.windows.net/marketingimages/westernwindows/landingpage/Box-2-Competitive-369x400.jpg');
      @include hide-tile-label();
    }

    &:nth-child(3) {
      background-image: url('https://36qyuzoj7fgnibljtmes.blob.core.windows.net/marketingimages/westernwindows/landingpage/Box-3-Support-369x400.jpg');
      @include hide-tile-label();
    }

    &:nth-child(4) {
      background-image: url('https://36qyuzoj7fgnibljtmes.blob.core.windows.net/marketingimages/westernwindows/landingpage/Box-4-Dealer-1139x171.jpg');
      background-size: contain;
      flex: 1 1 100%;
      flex-direction: row;
      background-position: top;
      @include hide-tile-label();

      /* Make the 4th tile show on a new row and span the full tile area width
      on the lg breakpoint and higher - otherwise it should respond like the 
      others.*/
      @include media-breakpoint-up(lg) {
        grid-column-end: span 3;
      }
    }
  }
}
