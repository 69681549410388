.multiLineActions {
  left: 50%;
  width: auto;
  transform: translateX(-50%);
  position: fixed;
  transition: bottom 0.2s ease;
  height: 63px;
  z-index: 2;
  background: $white;
  border-radius: $border-radius;
  box-shadow: 0px 22px 104px -6px rgba(0, 0, 0, 0.34);

  display: flex;
  flex-direction: row;
  bottom: 35px;
  user-select: none;
  justify-content: space-between;
  overflow: inherit;
}

.multiLineActions__linesWrapper {
  width: 63px;
  color: $white;
  background: $primary;
  border-radius: $border-radius 0 0 $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: default;
}

.multiLineActions__lines {
  font-size: 2em;
}

.multiLineActions__batchAction {
  display: flex;
  flex-direction: column;
  text-align: center;

  &:hover {
    color: $primary;
  }
}

.multiLineActions .wts-button {
  display: flex;
  height: 100%;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 0;

  // Inner wrapper
  > span {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 0;

    // Icon
    > svg {
      font-size: 1.5em;
      margin-top: 0;
      margin-bottom: 0;
    }

    // Text
    > span {
      font-size: .8em;
    }
  }

  &:hover {
    color: $wts-text;
    background-color: unset;

    svg {
      color: $primary;
    }
  }
}

.multiLineActions__batchActionWrapper {
  display: flex;
  position: relative;
  align-items: center;
}

.multiLineActions__batchActionCloseItem {
  display: flex;
  width: 63px;
  cursor: pointer;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  border-left: 2px solid $gray-200;
}